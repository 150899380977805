import { appImages } from "../constants/images";

const constantsValues = {
  "0x780a": {
    chainID: "0x780a",
    blockExplorer: "https://explorer.movementlabs.xyz/#/account/",
    ChainName: "Movement-M1",
    slug: "MoveM1",
    testnet: "true",
    rpcURL: "https://mevm.devnet.m1.movementlabs.xyz",
    logo: appImages.movementLogo,
  },
};

const defautlChainId = "0x780a";
export { defautlChainId };
export default constantsValues;
