import lighthouse from "@lighthouse-web3/sdk";

export const lighthouseClient = {
  uploadFile: async (file) => {
    try {
      const response = await lighthouse.upload([file], process.env.REACT_APP_LIGHTHOUSE_API);
      console.log("File uploaded to IPFS: ", { fileInfo: response.data });

      return {
        success: true,
        message: "File uploaded successfully.",
        fileHash: response.data.Hash,
      };
    } catch (error) {
      console.error("Error uploading file to IPFS: ", { error });
      return {
        success: false,
        message: "File upload error: File could not be uploaded to IPFS.",
      };
    }
  },

  uploadMetadata: async (name, description, createdBy, attributes, animation_url) => {
    const metadata = {
      name,
      description,
      createdBy,
      attributes,
      animation_url,
    };

    const metadataFile = new File([JSON.stringify(metadata, null, 2)], "metadata.json", { type: "application/json" });
    try {
      const response = await lighthouse.upload([metadataFile], process.env.REACT_APP_LIGHTHOUSE_API);
      console.log("Metadata uploaded to IPFS: ", { fileInfo: response.data });

      return {
        success: true,
        message: "Metadata uploaded successfully.",
        fileHash: response.data.Hash,
      };
    } catch (error) {
      console.error("Error uploading Metadata to IPFS: ", { error });
      return {
        success: false,
        message: "Metadata upload error: Metadata could not be uploaded to IPFS.",
      };
    }
  },
};
