import { toast } from "react-toastify";
import constantsValues from "../constants/constantsValues";

export default async function switchNetwork(metamaskProvider, chainId) {
  try {
    await metamaskProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainId }],
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4902) {
      toast.error(`Please Add  ${constantsValues[chainId].ChainName}\n`);
      try {
        if (chainId === "0x780a") {
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x780a",
                chainName: "Movement-M1",
                nativeCurrency: {
                  name: "MOVE",
                  symbol: "MOVE",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://explorer.movementlabs.xyz/#/account/"],
                rpcUrls: ["https://mevm.devnet.m1.movementlabs.xyz"],
              },
            ],
          });
        }
        window.location.reload();
        return;
      } catch (error) {
        console.log("error", error);
        let errordata = error.data ? error.data.message.split(":")[1] : error.message.split(":")[1];
        console.log("Error:", errordata);
        return;
      }
    }
    throw error;
  }
}
