import { createContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import switchNetwork from "../helper/switchNetwork";
import constantsValues, { defautlChainId } from "../constants/constantsValues";

const { ethereum } = window;

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [address, setAddress] = useState(null);
  const [provider, setProvider] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [chainToConnect, setChainToConnect] = useState(null);
  const [chainNotSupport, setChainNotSupport] = useState(undefined);

  const loadWeb3 = async () => {
    setChainNotSupport(undefined);

    try {
      const metamaskProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(metamaskProvider);

      const network = await metamaskProvider.getNetwork();
      const chainId = network.chainId.toString();
      const chainHex = ethers.utils.hexValue(Number(chainId));

      console.warn({ chainHex, chainId, constantsValues });

      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      let connectedAccounts = accounts;

      if (!constantsValues[chainHex] && !constantsValues[chainToConnect]) {
        setChainNotSupport(true);
        setAddress(connectedAccounts[0]);
        setChainId(chainId);
        setChainToConnect(chainHex);

        await switchNetwork(window.ethereum, defautlChainId);
        return;
      }

      if (connectedAccounts.length > 0) {
        if (chainHex !== chainToConnect && chainToConnect) {
          try {
            await switchNetwork(window.ethereum, chainToConnect);
            const prov = new ethers.providers.Web3Provider(window.ethereum);
            setProvider(prov);
            return;
          } catch (err) {
            window.location.reload();
          }
        }
        setAddress(connectedAccounts[0]);
        setChainId(chainId);
        setChainToConnect(chainHex);
        return;
      }

      if (chainToConnect === null) {
        setChainToConnect(defautlChainId);
        return;
      }
    } catch (err) {
      console.log("Error: ", { err });
    }
  };

  useEffect(() => {
    loadWeb3();
  }, [chainToConnect]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", async function (params) {
        setChainNotSupport(undefined);
        setChainToConnect(params);
      });
    }
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async function (params) {
        setAddress(params[0]);
      });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        address,
        setAddress,
        provider,
        chainId,
        chainToConnect,
        chainNotSupport,
        setChainToConnect,
      }}
    >
      {children}
      <ToastContainer />
    </AuthContext.Provider>
  );
};
